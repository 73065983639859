import React from 'react';
import { Tooltip } from 'antd';

const Footer = () => {
  return (
   <>
    {/* Footer #1 ============================================= */}
  <footer id="footer" className="footer footer-1">
  <div className="footer-container">
    {/* Widget Section ============================================= */}
    <div className="footer-widget">
      <div className="widget-container">
        <div className="info">
          <h3>Follow Us !</h3>
          <ul className="list-inline">
            <li><a href="https://www.facebook.com/orchidsnail/" target="_blank"><i className="fa fa-facebook" /></a></li>
            <li><a href="https://www.yelp.com/biz/orchids-nails-and-spa-tulsa" target="_blank"><i className="fa fa-yelp" /></a></li>
            <li><a href="https://www.google.com/search?hl=en&_ga=2.268631857.13750825.1598471717-1507255196.1578935786&q=Orchids%20Nails%20%26%20Spa&ludocid=7923268391581984539&lsig=AB86z5UL2JU1Ib-wrOWLiC7W5u34" target="_blank"><i className="fa fa-google" /></a></li>
            {/*<li><a href="#"><i class="fa fa-instagram"></i></a></li>*/}
          </ul>
        </div>
        <div className="subscription">
          <div className="row text-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h3>Sign Up For Amazing Deals !</h3>
              <div className="clearfix" />
              <a href="/sign-up" className="btn btn-signup">SIGN UP</a>
            </div>
          </div>
        </div>
      </div>
    </div>{/* .footer-widget end */}
    {/* Logo Widget ============================================= */}
    <div className="footer-logo">
      <div className="container">
        <div className="row google-map-row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="text-center">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12894.847797411998!2d-95.9761594!3d36.1005092!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6df51a9f7453271b!2sOrchids%20Nails%20%26%20Spa!5e0!3m2!1sen!2s!4v1598844685401!5m2!1sen!2s" width="100%" height={450} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="text-center" style={{padding: '3vh'}}>
              <img src={require('./../assets/images/logo/logo-light-footer.png').default} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="footer-info-widget">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="contact-info">
              <p>LOCATION</p>
              <p>4302 S Peoria Ave<br />Tulsa, Oklahoma 74105</p>
              <p>Phone: <a href="tel:9189352238">918-935-2238</a></p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="bio-info text-center">
              <br></br>
              <br></br>
              <p>We make it our core commitment to provide a comforting yet professional ambiance, while preserving the luxurious and modern salon etiquette. Our salon is proud to have passionate and diverse expert technicians always ready to service you. Thank you for choosing us for your beauty care! </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="copyright-info text-right">
              <p>BUSINESS HOURS</p>
              <p>Mon - Sat: 9AM - 7PM</p>
              <p>Sun: 12PM - 6PM</p>
              <p>OUR FRIENDS:</p>
              <p><a className="navbar-brand" href="http://poshnailstulsa.com/" target="_blank">
              <Tooltip title="Posh Nails Tulsa">
              <img src={require('./../assets/images/logo_poshnails.jpg').default} alt="Posh Nails" style={{width:'50%',height:'50%'}}/>
              </Tooltip>
              
            </a>
            </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="bio-info text-center">
              <p className="copyright-line">Copyright © Orchids Nails &amp; Spa</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
   </>
  );
};

export default Footer;
