import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import { useHistory } from 'react-router-dom';

const Gallery = () => {
  const history = useHistory();
  const next = () => {
   history.push("/gallery-2");
  };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Our Gallery</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Gallery</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* Gallery #1
    ===========================================*/}
    <section className="portfolio portfolio-vintage portfolio-3col portfolio-animation pb-40">
      <div className="container">
        <div className="row heading heading-2 mb-70">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Our Works of Art</div>
          </div>{/* .col-lg-12 end */}
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Created with Pride.</h2>
          </div>
        </div>
        {/* .row end */}
      </div>{/* .container end */}
      <div className="container">
        <div id="portfolio-all" className="row">
          {/* Portfolio Item #1 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                
                <img src={require('./../assets/images/portfolio/1.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/1.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #2 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Web">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/3.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/3.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #3 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/2.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/2.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #4 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Interior filter-Print">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/4.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/4.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #5 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Web ">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/5.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/5.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #6 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/6.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/6.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}

          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227144/orchids-nails-salon/1_rvytsp.jpg' alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227144/orchids-nails-salon/1_rvytsp.jpg' alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}

          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
          <div className="portfolio-item-box">
            <div className="portfolio-img">
              <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227145/orchids-nails-salon/2_jbkk0d.jpg' alt="Gallery Pic" />
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <div className="portfolio-meta">
                    <div className="portfolio-title">
                      <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                    </div>
                  </div>{/* .Portfolio-meta end */}
                  <div className="portfolio-action">
                    <div className="portfolio-zoom-border">
                      <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227144/orchids-nails-salon/1_rvytsp.jhttps://res.cloudinary.com/dtopnho1y/image/upload/v1606227145/orchids-nails-salon/2_jbkk0d.jpgpg' alt="Gallery Pic">
                      <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>{/* .Portfolio-action end */}
                </div>{/* .portfolio-hover-content end */}
              </div>{/* .Portfolio-hover end */}
            </div>{/* .Portfolio-img end */}
          </div>{/* .Portfolio-item-box end */}
        </div>{/* . portfolio-item  end */}

        <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
        <div className="portfolio-item-box">
          <div className="portfolio-img">
            <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227157/orchids-nails-salon/3_uvqrfv.jpg' alt="Gallery" />
            <div className="portfolio-hover">
              <div className="portfolio-hover-content">
                <div className="portfolio-meta">
                  <div className="portfolio-title">
                    <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                  </div>
                </div>{/* .Portfolio-meta end */}
                <div className="portfolio-action">
                  <div className="portfolio-zoom-border">
                    <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227157/orchids-nails-salon/3_uvqrfv.jpg' alt="Gallery">
                    <i className="fa fa-plus" />
                    </a>
                  </div>
                </div>{/* .Portfolio-action end */}
              </div>{/* .portfolio-hover-content end */}
            </div>{/* .Portfolio-hover end */}
          </div>{/* .Portfolio-img end */}
        </div>{/* .Portfolio-item-box end */}
      </div>{/* . portfolio-item  end */}

      <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
      <div className="portfolio-item-box">
        <div className="portfolio-img">
          <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227129/orchids-nails-salon/4_zh7ywl.jpg' alt="Gallery" />
          <div className="portfolio-hover">
            <div className="portfolio-hover-content">
              <div className="portfolio-meta">
                <div className="portfolio-title">
                  <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                </div>
              </div>{/* .Portfolio-meta end */}
              <div className="portfolio-action">
                <div className="portfolio-zoom-border">
                  <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227129/orchids-nails-salon/4_zh7ywl.jpg' alt="Gallery">
                  <i className="fa fa-plus" />
                  </a>
                </div>
              </div>{/* .Portfolio-action end */}
            </div>{/* .portfolio-hover-content end */}
          </div>{/* .Portfolio-hover end */}
        </div>{/* .Portfolio-img end */}
      </div>{/* .Portfolio-item-box end */}
    </div>{/* . portfolio-item  end */}

    <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
    <div className="portfolio-item-box">
      <div className="portfolio-img">
        <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227131/orchids-nails-salon/5_auxlzb.jpg' alt="Gallery" />
        <div className="portfolio-hover">
          <div className="portfolio-hover-content">
            <div className="portfolio-meta">
              <div className="portfolio-title">
                <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
              </div>
            </div>{/* .Portfolio-meta end */}
            <div className="portfolio-action">
              <div className="portfolio-zoom-border">
                <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227131/orchids-nails-salon/5_auxlzb.jpg' alt="Gallery">
                <i className="fa fa-plus" />
                </a>
              </div>
            </div>{/* .Portfolio-action end */}
          </div>{/* .portfolio-hover-content end */}
        </div>{/* .Portfolio-hover end */}
      </div>{/* .Portfolio-img end */}
    </div>{/* .Portfolio-item-box end */}
  </div>{/* . portfolio-item  end */}

  <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
  <div className="portfolio-item-box">
    <div className="portfolio-img">
      <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227148/orchids-nails-salon/8_liqv6p.jpg' alt="Gallery" />
      <div className="portfolio-hover">
        <div className="portfolio-hover-content">
          <div className="portfolio-meta">
            <div className="portfolio-title">
              <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
            </div>
          </div>{/* .Portfolio-meta end */}
          <div className="portfolio-action">
            <div className="portfolio-zoom-border">
              <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227148/orchids-nails-salon/8_liqv6p.jpg' alt="Gallery">
              <i className="fa fa-plus" />
              </a>
            </div>
          </div>{/* .Portfolio-action end */}
        </div>{/* .portfolio-hover-content end */}
      </div>{/* .Portfolio-hover end */}
    </div>{/* .Portfolio-img end */}
  </div>{/* .Portfolio-item-box end */}
  </div>{/* . portfolio-item  end */}

  
  <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
  <div className="portfolio-item-box">
    <div className="portfolio-img">
      <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227146/orchids-nails-salon/7_mxl5ie.jpg' alt="Gallery" />
      <div className="portfolio-hover">
        <div className="portfolio-hover-content">
          <div className="portfolio-meta">
            <div className="portfolio-title">
              <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
            </div>
          </div>{/* .Portfolio-meta end */}
          <div className="portfolio-action">
            <div className="portfolio-zoom-border">
              <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227146/orchids-nails-salon/7_mxl5ie.jpg' alt="Gallery">
              <i className="fa fa-plus" />
              </a>
            </div>
          </div>{/* .Portfolio-action end */}
        </div>{/* .portfolio-hover-content end */}
      </div>{/* .Portfolio-hover end */}
    </div>{/* .Portfolio-img end */}
  </div>{/* .Portfolio-item-box end */}
</div>{/* . portfolio-item  end */}

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227133/orchids-nails-salon/6_hbckom.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>{/* .Portfolio-meta end */}
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227133/orchids-nails-salon/6_hbckom.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>{/* .Portfolio-action end */}
      </div>{/* .portfolio-hover-content end */}
    </div>{/* .Portfolio-hover end */}
  </div>{/* .Portfolio-img end */}
</div>{/* .Portfolio-item-box end */}
</div>{/* . portfolio-item  end */}




<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227149/orchids-nails-salon/10_hzh8zb.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227149/orchids-nails-salon/10_hzh8zb.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227151/orchids-nails-salon/11_tpo0rl.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227151/orchids-nails-salon/11_tpo0rl.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227155/orchids-nails-salon/12_f2zftl.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227155/orchids-nails-salon/12_f2zftl.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/21_pkp5ym.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/21_pkp5ym.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/22_t6auky.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/22_t6auky.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227162/orchids-nails-salon/15_wewjwz.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227162/orchids-nails-salon/15_wewjwz.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227169/orchids-nails-salon/16_lf9ini.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227169/orchids-nails-salon/16_lf9ini.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227166/orchids-nails-salon/17_qv5qxt.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227166/orchids-nails-salon/17_qv5qxt.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227122/orchids-nails-salon/18_qlx5ck.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227122/orchids-nails-salon/18_qlx5ck.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227158/orchids-nails-salon/14_mix1be.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227158/orchids-nails-salon/14_mix1be.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>









        </div>{/* .row end */}
        <ul className="ant-pagination" unselectable="unselectable" style={{textAlign:'center'}}>
    <li title="Previous Page" className="ant-pagination-prev ant-pagination-disabled" aria-disabled="true">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" disabled>
            <span role="img" aria-label="left" className="anticon anticon-left">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                </svg>
            </span>
        </button>
    </li>
    <li title="{1}" className="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active" tabindex="{0}"><a rel="nofollow">1</a></li>
    <li title="{2}" className="ant-pagination-item ant-pagination-item-2" tabindex="{0}"><a href="/gallery-2" rel="nofollow">2</a></li>
    <li title="{3}" className="ant-pagination-item ant-pagination-item-3" tabindex="{0}"><a href="/gallery-3" rel="nofollow">3</a></li>
   
    <li title="Next Page" className="ant-pagination-next" aria-disabled="false" tabindex="{0}">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" onClick={next}>
            <span role="img" aria-label="right" className="anticon anticon-right">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
                </svg>
            </span>
        </button>
    </li>
</ul>

      </div>
      {/* .container end */}



    </section>{/* #portfolio-masonry end */}
  </>
  
  );
};

export default Gallery;
