import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';

const Gallery3 = ({history}) => {
   const previous = () => {
    history.push("/gallery-2")
   };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Our Gallery</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Gallery</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* Gallery #1
    ===========================================*/}
    <section className="portfolio portfolio-vintage portfolio-3col portfolio-animation pb-40">
      <div className="container">
        <div className="row heading heading-2 mb-70">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Our Works of Art</div>
          </div>{/* .col-lg-12 end */}
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Created with Pride.</h2>
          </div>
        </div>
        {/* .row end */}
      </div>{/* .container end */}
      <div className="container">
        <div id="portfolio-all" className="row">

            <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
                <div className="portfolio-item-box">
                <div className="portfolio-img">
                    <video
                    src='https://res.cloudinary.com/dtopnho1y/video/upload/v1606227256/orchids-nails-salon/video2_kd29i1.mp4'
                    controls style={{height:'550px'}}
                ></video>
                </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
                <div className="portfolio-item-box">
                <div className="portfolio-img">
                    <video
                    src='https://res.cloudinary.com/dtopnho1y/video/upload/v1606227211/orchids-nails-salon/video-3_ohd7ju.mp4'
                    controls style={{height:'550px'}}
                ></video>
                </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
                <div className="portfolio-item-box">
                <div className="portfolio-img">
                    <video
                    src='https://res.cloudinary.com/dtopnho1y/video/upload/v1606227171/orchids-nails-salon/vdeo4_zfnjtw.mp4'
                    controls style={{height:'550px'}}
                ></video>
                </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
                <div className="portfolio-item-box">
                <div className="portfolio-img">
                    <video
                    src='https://res.cloudinary.com/dtopnho1y/video/upload/v1606227129/orchids-nails-salon/video-1606204368_p3ysif.mp4'
                    controls style={{height:'550px'}}
                ></video>
                </div>
                </div>
            </div>







        </div>{/* .row end */}
        <ul className="ant-pagination" unselectable="unselectable" style={{textAlign:'center'}}>
    <li title="Previous Page" className="ant-pagination-prev" aria-disabled="true">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" onClick={previous}>
            <span role="img" aria-label="left" className="anticon anticon-left">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                </svg>
            </span>
        </button>
    </li>
    <li title="{1}" className="ant-pagination-item ant-pagination-item-1" tabindex="{0}"><a href="/gallery" rel="nofollow">1</a></li>
    <li title="{2}" className="ant-pagination-item ant-pagination-item-2" tabindex="{0}"><a href="/gallery-2" rel="nofollow">2</a></li>
    <li title="{3}" className="ant-pagination-item ant-pagination-item-3 ant-pagination-item-active" tabindex="{0}"><a rel="nofollow">3</a></li>
   
    <li title="Next Page" className="ant-pagination-next ant-pagination-disabled" aria-disabled="false" tabindex="{0}">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" disabled>
            <span role="img" aria-label="right" className="anticon anticon-right">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
                </svg>
            </span>
        </button>
    </li>
</ul>

      </div>
      {/* .container end */}



    </section>{/* #portfolio-masonry end */}
  </>
  
  );
};

export default Gallery3;
