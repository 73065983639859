import React from 'react';
import { Link } from 'react-router-dom';
import { Result,Spin} from 'antd';
import { useState } from 'react';


const SignUp = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    birthday:'',
    buttonText: 'Submit',
    formData: new FormData(),
  });
  const {
    firstName,
    lastName,  
    email,
    phone,
    birthday,
    buttonText,
    formData,
  } = values;
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };
  const signUp = (information) => {
    return fetch(
      `https://www.flexyform.com/f/7ea7145f657399ee41c3020b21935fc1850415af`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: information,
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValues({ ...values, buttonText: 'Sending ... ' });
    try {
      signUp(formData).then((data) => {
        setValues({
          ...values,
          firstName: '',
          lastName:'',
          phone: '',          
          email: '',  
          birthday:'',        
          buttonText:'Submit'         
        });
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      setValues({
        ...values,
        buttonText: 'Submit',
      });
    }
  };
  return (
    <>
  <section id="page-title" className="page-title">
    <div className="container-fluid bg-overlay bg-section">
     
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="title title-1">
            <div className="title-content">
              <div className="title-heading">
                <h1>Sign Up</h1>
              </div>
              <ol className="breadcrumb">
                <li>
                <Link to="/">Home</Link>
                </li>
                <li className="active">Sign Up</li>
              </ol>
            </div>
          </div>        
        </div>   
      </div>   
    </div>  
  </section>
 
  <section className="service service-1">
    <div className="container">
      <div className="row heading heading-2 mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <div className="wrapperdeal">
                <div id="box_signup">
                 
                  <div id="row_title" className="row_title_top">Sign Up to receive future promotions &amp; birthday gifts to your inbox.</div>
                  <div className="signdeal">
                  {!success && (
                    <>
                    {loading ? (
                      <>
                      <Spin tip="Sending ...">
                      <form id="form" onSubmit={handleSubmit}>
                      <div className="formrow">
                        <div className="formrowtop">First Name <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" name="firstName" id="firstName" onChange={handleChange('firstName')}
                        value={firstName} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Last Name <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" name="lastName" id="lastName" onChange={handleChange('lastName')}
                        value={lastName} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Email <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" id="email" name="email" onChange={handleChange('email')}
                        value={email} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Phone <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" id="phone" name="phone" onChange={handleChange('phone')}
                        value={phone} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Birthday <span style={{color: 'Red'}}></span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" id="birthday" name="birthday" placeholder="MM/DD/YY" onChange={handleChange('birthday')}
                        value={birthday}/></div>
                      </div>
                      <div className="formrow buttonrow">
                      <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>        
                      </div>
                     
                    </form>
                    
                      </Spin>
                      </>
                    ):(<>
                      <form id="form" onSubmit={handleSubmit}>
                      <div className="formrow">
                        <div className="formrowtop">First Name <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" name="firstName" id="firstName" onChange={handleChange('firstName')}
                        value={firstName} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Last Name <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" name="lastName" id="lastName" onChange={handleChange('lastName')}
                        value={lastName} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Email <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="email" id="email" name="email" onChange={handleChange('email')}
                        value={email} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Phone <span style={{color: 'Red'}}>*</span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" id="phone" name="phone" onChange={handleChange('phone')}
                        value={phone} required /></div>
                      </div>
                      <div className="formrow">
                        <div className="formrowtop">Birthday <span style={{color: 'Red'}}></span>:</div>
                        <div className="formrowtext"><input className="txtbox" type="text" id="birthday" name="birthday" placeholder="MM/DD/YY" onChange={handleChange('birthday')}
                        value={birthday}/></div>
                      </div>
                      <div className="formrow buttonrow">
                      <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>        
                      </div>
                     
                    </form>
                    
                      </>)}
                    </>
                  )}
                    
                  {success && (
                    <div className="contact_party">
                    <Result
                    status="success"
                    title="Thank you! You Signed Up Successfully!"
                    
                    />
                   
                    </div>
                  )}
                  </div>
                </div>
              </div>
             
             			
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
    </div>
    {/* .container end */}
  </section>
  {/* #service1 end */}
</>

  
  );
};

export default SignUp;
