import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Select,Carousel,DatePicker  } from 'antd';
import moment from 'moment'
import Typewriter from 'typewriter-effect';
import Slider1 from "../assets/images/sliders/slide-bg/demo-1.jpg";
import Slider2 from "../assets/images/sliders/slide-bg/demo-2.jpg";

const contentStyle = {
  height: '600px',
  color: '#fff',
  textAlign: 'center',
  background: '#364d79',
};
const carousel1 = {
  backgroundRepeat: 'no-repeat',
	backgroundImage: "url(" + Slider1 +")" ,
	backgroundSize: 'cover', 
	backgroundPosition: 'center center',
	width: '100%',
	height: '100%', 
};
const carousel2 = {
  backgroundRepeat: 'no-repeat',
	backgroundImage: "url(" + Slider2 +")" ,
	backgroundSize: 'cover', 
	backgroundPosition: 'center center',
	width: '100%',
	height: '100%', 
};
const Home = () => {
  const { Option } = Select;
  const dateFormat = 'MM/DD/YYYY';
  return (
    <>
    <section id="slider" className="slider slider-1" style={{paddingBottom:'60px'}}>
    <div class="rev_slider_wrapper">   
            <div className="col-sm-12 col-md-12 col-lg-12">
            <Carousel autoplay>
            <div>
              <div style={contentStyle} >
                <div style={carousel1} />
              </div>
            </div>
            <div>
              <div style={contentStyle} >
                <div style={carousel2} />
              </div>
            </div>
          </Carousel>
            </div>        
    </div>
    </section>

        <section id="about5" className="about about-4 about-5" style={{paddingTop:'0'}}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="heading heading-4 mb-70">
                  <div className="subheading" style={{fontSize:'28px'}}><Typewriter
                  options={{
                    strings: 'Welcome to Orchids Nails & Spa!',
                    autoStart: true,
                    loop: true,
                  }} /></div>
                  <h2>Experts in Colors !</h2>
                </div>
              </div>
              {/* .col-lg-9 end */}
            </div>
            <div className="row">
              {/* About Panel #1 */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="about-panel">
                  <div className="about-icon">
                    <h6><img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} /></h6>
                  </div>
                  <div className="about-content">
                    <p>Our commitment is to give each and every client what they want and need for their beautiful nails today and in the future. Make their nails look as good as it makes them feel. We are the pros at creating the perfect look for you. </p>
                  </div>
                </div>
              </div>
              {/* .col-lg-4 end */}
              {/* About Panel #2 */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="about-panel">
                  <div className="about-icon">
                    <h6><img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} /></h6>
                  </div>
                  <div className="about-content">
                    <p>We are here to help you achieve your greatest beauty needs while continuously educating you in styles and trends and how to best care of your nails.</p>
                  </div>
                </div>
              </div>
              {/* .col-lg-4 end */}
              {/* About Panel #4 */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="about-panel">
                  <div className="about-icon">
                    <h6><img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} /></h6>
                  </div>
                  <div className="about-content">
                    <p>Our definition of a salon is "a hall for exhibition of art". Here at our salon, we want to make every style we create to be a work of art. The Perfect Look.</p>
                  </div>
                </div>
              </div>
              {/* .col-lg-4 end */}
            </div>
            {/* .row end */}
          </div>
          {/* .container end */}
        </section>
        <section id="portfolio-vintage" className="portfolio portfolio-vintage portfolio-3col  portfolio-animation bg-gray pb-40">
          <div className="container">
            <div className="row heading heading-2 mb-70">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="subheading">Our Works of Art</div>
              </div>{/* .col-lg-12 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <h2>Created with Pride.</h2>
              </div>
            </div>
            {/* .row end */}
          </div>{/* .container end */}
          <div className="container">
        <div id="portfolio-all" className="row">
          {/* Portfolio Item #1 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                
                <img src={require('./../assets/images/portfolio/1.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/1.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #2 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Web">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/3.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/3.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #3 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/2.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/2.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #4 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Interior filter-Print">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/4.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/4.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #5 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Branding filter-Web ">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/5.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/5.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
          {/* Portfolio Item #6 */}
          <div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
            <div className="portfolio-item-box">
              <div className="portfolio-img">
                <img src={require('./../assets/images/portfolio/6.jpg').default} alt="Portfolio Item" />
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <div className="portfolio-meta">
                      <div className="portfolio-title">
                        <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
                      </div>
                    </div>{/* .Portfolio-meta end */}
                    <div className="portfolio-action">
                      <div className="portfolio-zoom-border">
                        <a className="img-popup zoom" href={require('./../assets/images/portfolio/6.jpg').default} alt="gallery_pic">
                        <i className="fa fa-plus" />
                        </a>
                      </div>
                    </div>{/* .Portfolio-action end */}
                  </div>{/* .portfolio-hover-content end */}
                </div>{/* .Portfolio-hover end */}
              </div>{/* .Portfolio-img end */}
            </div>{/* .Portfolio-item-box end */}
          </div>{/* . portfolio-item  end */}
        </div>{/* .row end */}
      </div>
      {/* .container end */}
        </section>{/* #portfolio-masonry end */}
        {/* Service #1
  ============================================= */}
        <section id="service1" className="service service-1">
          <div className="container">
            <div className="row heading heading-2 mb-60">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="subheading">Our Services</div>
              </div>
              {/* .col-lg-12 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <h2>Simply The Best !</h2>
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-6">
                <p>We understand the importance of beautiful nails. <br />Come, relax and enjoy unequal services.</p>
              </div>
              {/* .col-lg-6 end */}
            </div>
            {/* .row end */}
            <div className="row mb-60">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Manicures</h3>
                    <p>Be more beautiful and stylish with our manicure service. Take the time from your busy schedule to help your nails look their best.</p>
                    <a className="service-more" href="services-2.html#Manicure"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Pedicures</h3>
                    <p>Indulge your feet with our exclusive spa pedicures. We offer the best combination of relaxation &amp; sanitation !</p>
                    <a className="service-more" href="services-2.html#Pedicures"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Nail Enhancements</h3>
                    <p>Knowing that your nails look well groomed and polished boosts self-confidence and can make you feel more professional and prettier.</p>
                    <a className="service-more" href="services-2.html#Enhancements"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
            </div>
            {/* .row end */}
            {/* .row end */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Add On</h3>
                    <p>Knowing that your nails look well groomed and polished boosts self-confidence and can make you feel more professional and prettier.</p>
                    <a className="service-more" href="services-2.html#Add-On"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Eyelash Extensions </h3>
                    <p>Do you enjoy being pampered? Come and check out our glamorous nail sets, plus hands and feet hot stone massage, exfoliation &amp; hydration treatment, and much more. We are the pros!</p>
                    <a className="service-more" href="services-2.html#Eyelash"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <img src={require('./../assets/images/favicon/icon-nail.png').default} alt="icons" height={50} width={50} />
                  </div>
                  <div className="service-content">
                    <h3>Waxing</h3>
                    <p>We're committed to making you comfortable, both through friendly, straightforward service and the very best waxing products available. </p>
                    <a className="service-more" href="services-2.html#Waxing"><i className="celia-arrow-long-right" /><span>Our Services</span></a>
                  </div>
                </div>
                {/* .service-panel end */}
              </div>
              {/* .col-lg-4 end */}
            </div>
            {/* .row end */}
          </div>
          {/* .container end */}
        </section>
        {/* #service1 end */}
        {/* Team #1
  ============================================= */}
        <section id="team1" className="team team-1 bg-gray">
          <div className="container">
            <div className="heading heading-2">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="subheading">Let Us Be</div>
                </div>
                {/* .col-lg-12 end */}
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <h2>Your Destination !</h2>
                </div>
                {/* .col-lg-4 end */}
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <p>We love what we do and we do it with passion. We want to make sure you feel well-polished at all times. Making sure you feel your best is why we exist.</p>
                </div>{/* .col-lg-6 end */}
              </div>
            </div>{/* .row end */}
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="team-panel">
                  <div className="team-img">
                    <img src={require('./../assets/images/others/happy.jpg').default} alt="happy-hour" />
                    <div className="team-overlay">
                      <a href="happy-hour.html">More</a>
                    </div>
                  </div>
                  <div className="team-content">
                    <h3><a href="happy-hour.html" style={{color: '#333'}}>Happy Hour</a></h3>
                  </div>
                </div>{/* .team-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="team-panel">
                  <div className="team-img">
                    <img src={require('./../assets/images/others/giftcard.jpg').default} alt="e-gift" />
                    <div className="team-overlay">
                      <a href="egift.html">Buy E-Gift</a>
                    </div>
                  </div>
                  <div className="team-content">
                    <h3><a href="egift.html" style={{color: '#333'}}>E-Gift</a></h3>
                  </div>
                </div>{/* .team-panel end */}
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="team-panel">
                  <div className="team-img">
                    <img src={require('./../assets/images/others/party.jpg').default} alt="host-a-party" />
                    <div className="team-overlay">
                      <a href="party.html">Book Now</a>
                    </div>
                  </div>
                  <div className="team-content">
                    <h3><a href="party.html" style={{color: '#333'}}>Host A Party</a></h3>
                  </div>
                </div>{/* .team-panel end */}
              </div>
              {/* .col-lg-4 end */}
            </div>
            {/* .row end */}
          </div>
          {/* .container end */}
        </section>
        {/* #team1 end */}
        {/* Clients #2 =============================================*/}
        <section id="clients2" className="clients clients-1 clients-2 pb-80">
          <div className="container">
            <div className="row heading heading-2">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="subheading">Our Products</div>
              </div>
              {/* .col-lg-12 end */}		
              <div className="col-sm-12 col-md-12 col-lg-4">
                <h2>Be Bold. <br />Be Beautiful !</h2>
              </div>
              {/* .col-lg-4 end */}
              <div className="col-sm-12 col-md-12 col-lg-6">
                <p>The love of beauty is taste. The creation of beauty is art.</p>
              </div>
              {/* .col-lg-6 end */}
            </div>
            {/* .row end */}
         
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="owl-carousel">
                    {/* Client #1 */}
                    <div className="client" style={{width: '168.333px'}}>
                    <img src={require('./../assets/images/clients/1.jpg').default} alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: '2'}}>
                    <img src={require('./../assets/images/clients/2.jpg').default} alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: 20}}>
                    <img src={require('./../assets/images/clients/3.jpg').default} alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: 20}}>
                    <img src={require('./../assets/images/clients/4.jpg').default}alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: 20}}>
                    <img src={require('./../assets/images/clients/5.jpg').default} alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: 20}}>
                    <img src={require('./../assets/images/clients/6.jpg').default} alt="client" />
                    </div>
            
                    <div className="client" style={{width: '168.333px', marginRight: 20}}>
                    <img src={require('./../assets/images/clients/7.jpg').default} alt="client" />
                    </div>
                    {/* .client end */}
                </div>
                {/* #clients end */}
            </div>
            {/* .col-lg-12 end */}
            </div>


          </div>
          
        </section>

        
        
        
  </>
  
  );
};

export default Home;
