import React from 'react';
import { Link } from 'react-router-dom';
const Promotions = () => {
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
      
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Our Promotions</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Promotions</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* Service #1
  ===========================================*/}
    <section className="service service-1">
      <div className="container">
        <div className="row heading heading-2 mb-60">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="service-panel">
              <div className="service-content service-block">
                <p className="text-center">We currently don't have any promotions. Please check back later. Thank you.</p>
              </div>
              {/* <div class="service-content service-block">
              <div class="group_promotion text-center">
                        <div class="promo_img"><img  alt="Promotion2" src="assets/images/promotions/contego2.jpg"/></div>
                <a href="promotions/promotion1.html" class="print" target="_blank"><img  alt="" src="assets/images/promotions/print.png"/></a>
                    </div>
            </div> */}
            </div>
            {/* .service-panel end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #service1 end */}
  </>
  
  );
};

export default Promotions;
