import React from 'react';
import { Link } from 'react-router-dom'; 

const Services = () => {
  return (
   <>
   <section id="page-title" className="page-title">
    <div className="container-fluid bg-overlay bg-section" >
     
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="title title-1">
            <div className="title-content">
              <div className="title-heading">
                <h1>Our Services</h1>
              </div>
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Services</li>
              </ol>
            </div>
          </div>
          {/* .page-title end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
    </div>
    {/* .container end */}
  </section>
  {/* #page-title end */}
  {/* Service #1
===========================================*/}
  <section id="service1" className="service service-1">
    <div className="container">
      {/* MANICURE */}
      <a name="Manicure" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Manicure</h2>
            <a id="manicure" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/1.jpg').default} alt="manicure" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>Be more beautiful and stylish with our manicure service. Take the time from your busy schedule to help your nails look their best.</p>
        </div>
      </div>
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Classic Manicure <span className="price-r">$20</span></h3>
              <p>Enjoy a warm hand soak with Himalayan pink salt. Then, we trim and shape your nails followed with cuticle nourishment, hand massage, and intense moisturization with cocoa and coconut cream. We finish the manicure with warm towels and the application of polish</p>
              <h3>Summer Citrus Manicure <span className="price-r">$35</span></h3>
              <p>This upgraded manicure provides you refreshing citrus soak with Himalayan pink salt and citrus essential oil, as well as fresh lemon, lime, and orange slices. We exfoliate your hand with a hand-made citrus brown sugar scrub and massage with coconut cream. We finish with warm towel wrap and the application of polish.</p>
              <h3>Autumn Chamomile Manicure <span className="price-r">$45</span></h3>
              <p>The chamomile manicure incorporates all the treatments in the Classic Manicure and provides healing, calming, soothing, and antibacterial benefits to your hand. Chamomile also acts as effective aromatherapy for anxiety and is full of antioxidants. This potent herbal mixture is designed to soothe stressed skin and aching muscles. The treatment is finished with aromatherapy and the application of a polish of your choice. This also includes a brown sugar scrub with chamomile flowers, warm neck wrap, paraffin wax treatment, warm towel wrap, and the application of polish</p>
              <h3>Spring Rose Milky Manicure<span className="price-r">$55 </span></h3>
              <p>Rose petal hand soak and warm milk can make your hands glow. This beautiful manicure includes rose essential oil, rose petals, rose brown sugar scrub, hand massage with cocoa coconut cream, and hot stone massage. We finish with warm towels and the application of polish.</p>
              <h3>Winter Botanical Herbal Manicure <span className="price-r">$65</span></h3>
              <p>This top-notch manicure is designed for the coolest people on earth ! This includes sugar scrub made from rosemary. Lavender, ginger root, peppermint, and eucalyptus. A creamy coconut massage can soothe tired hands and provide relaxation for the mind and body. Hot stones and a warm herbal bag will soothe achy hands. Paraffin wax, green tea mask, hand massage, and hot stone massage will bring you to a wonderful world of relaxation. This manicure will please you with many benefits including stress relief and congestion relief.</p>
            </div>
          </div>
        </div>
      </div>
      {/* .row end */}
      {/* PEDICURE */}
      <a name="Pedicures" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Pedicures</h2>
            <a id="pedicure" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/3.jpg').default} alt="pedicure" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>Indulge your feet with our exclusive spa pedicures. We offer the best combination of relaxation &amp; sanitation !</p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Classic Pedicure <span className="price-r">$39 </span></h3>
              <p>Enjoy a warm foot soak with Himalayan pink salt. We follow with nails trimming and shaping, cuticle nourishment, foot massage, and intense moisturization with cocoa and coconut cream. We finish the manicure with warm towels and the application of polish</p>
              <h3>Summer citrus pedicure  <span className="price-r">$49 </span></h3>
              <p>This upgraded pedicure provides you refreshing citrus soak with Himalayan pink salt and citrus essential oil, as well as fresh lemon, lime, and orange slices. We exfoliate your legs with a hand-made citrus brown sugar scrub and massage with coconut butter. We finish with warm towel wrap and the application of polish.</p>
              <h3>Autumn Chamomile Pedicure  <span className="price-r">$69 </span></h3>
              <p>This upgraded pedicure provides you refreshing citrus soak with Himalayan pink salt and citrus essential oil, as well as fresh lemon, lime, and orange slices. We exfoliate your legs with a hand-made citrus brown sugar scrub and massage with coconut butter. We finish with warm towel wrap and the application of polish.</p>
              <h3>Spring Rose Milky Pedicure  <span className="price-r">$79 </span></h3>
              <p>No more hiding your feet ! Get fabulous legs with a regular rose petal foot soak and warm milk. This beautiful pedicure includes rose essential oil, rose petals, rose brown sugar, foot massage with cocoa coconut cream, hot stone massage, mask, and paraffin treatments. We finish with warm towels and the application of polish.</p>
              <h3>Winter Botanical Herbal Pedicure  <span className="price-r">$89 </span></h3>
              <p>This top-notch pedicure is designed for the coolest people on earth ! This includes sugar scrub made from rosemary, lavender, ginger root, peppermint, and eucalyptus. A creamy coconut massage can soothe tired feet and provide relaxation for the mind and body. Hot stones and a warm herbal bag will soothe achy legs. This treatment lasts 75 minutes and will remove your calluses. Ultimately, paraffin wax treatment, green tea mask, foot massage, and hot stone massage will bring you to a wonderful world of relaxation. This pedicure will please you with many benefits including stress relief and congestion relief.</p>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
      {/* Nail Enhancements */}
      <a name="Enhancements" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Nail Enhancements</h2>
            <a id="enhancements" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/4.jpg').default} alt="combo" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>All-natural products that help treat skin conditions and improve relaxation. The benefits of these services are too good to miss.</p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Healthy Dip / French  <span className="price-r">$50/$55</span></h3>
              <h3>Healthy Shellac / French <span className="price-r">$40/$45</span></h3>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
      {/* Add on */}
      <a name="Add-On" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Add On </h2>
            <a id="nail-care" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/add-on.jpg').default} alt="children" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>Just sit back, relax and enjoy while we take care of your little divas. We'll make you feel like royalties ! </p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Shellac color <span className="price-r">$25 </span></h3>
              <h3>Shellac French  <span className="price-r">$30 </span></h3>
              <h3>Paraffin  <span className="price-r">$15 </span></h3>
              <h3>French  <span className="price-r">$5 </span></h3>
              <h3>Design <span className="price-r">$10 </span></h3>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
      {/* Eyelash Extensions */}
      <a name="Eyelash" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Eyelash Extensions </h2>
            <a id="nail-care" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/6.jpg').default}  alt="children" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>Just sit back, relax and enjoy while we take care of your little divas. We'll make you feel like royalties ! </p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Full Set <span className="price-r">$150 </span></h3>
              <h3>Refill  <span className="price-r">$45+ </span></h3>
              <h3>Express   <span className="price-r">$45 </span></h3>
              <h3>Eyebrow tinting   <span className="price-r">$25 </span></h3>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
      {/* Facial */}
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Facial </h2>
            <a id="nail-care" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/facial.jpg').default}  alt="children" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>With all the natural ingredients being used to pamper your skin there are zero chances of having side effects of an organic facial. The organic products provide essential minerals, antioxidants, and help in cell regeneration.</p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Mini  Facial ( 30 minutes ) <span className="price-r">$50 </span></h3>
              <p>Enjoy an organic facial that will stimulate, detoxify, firm, and hydrate your skin through exfoliation and hydration treatments. This indulgent and aromatic facial experience gently brightens your skin.</p>
              <h3>Day Spa Facial ( 60 minutes ) <span className="price-r">$75 </span></h3>
              <p>Enjoy hydrated and noticeably smoother skin with the infusion of antioxidants and removal of surface impurities with acai berry juice. The natural enzyme content increases collagen production, leaving the skin firm and radiant with increased elasticity. It comes with streaming, pore scrubbing, cleansing facial massage, and a neck and shoulder massage for complete relaxation. </p>
              <h3>Signature Orchids Facial ( 80 minutes )  <span className="price-r">$95 </span></h3>
              <p>In this treatment, your skin and mind are nourished. This treatment includes the Day Spa Facial, along with a hand and arm massage to help blood circulation.</p>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
      {/* Waxing*/}
      <a name="Waxing" />
      <div className="row heading heading-2 mb-60 bg-gray service-title-row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="subheading">
            <h2>Waxing</h2>
            <a id="waxing" />
          </div>
        </div>
        {/* .col-lg-12 end */}
        <div className="col-sm-12 col-md-12 col-lg-4">
          <img src={require('./../assets/images/service/7.jpg').default}  alt="add-on" />
        </div>
        {/* .col-lg-4 end */}
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p>We're committed to making you comfortable, both through friendly, straightforward service and the very best waxing products available.</p>
        </div>
        {/* .col-lg-6 end */}
      </div>
      {/* .row end */}
      <div className="row mb-60">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="service-panel">
            <div className="service-content service-block">
              <h3>Eyebrows  <span className="price-r">$15 </span></h3>
              <h3>Lips  <span className="price-r">$7 </span></h3>
              <h3>Chin  <span className="price-r">$10 </span></h3>
              <h3>Sideburns  <span className="price-r">$25 </span></h3>
              <h3>Full Face  <span className="price-r">$50 </span></h3>
              <h3>Stomach  <span className="price-r">$30+ </span></h3>
              <h3>Ears  <span className="price-r">$20+ </span></h3>
              <h3>Underarms  <span className="price-r">$30 </span></h3>
              <h3>Half Arms  <span className="price-r">$35+ </span></h3>
              <h3>Full Arms  <span className="price-r">$60+ </span></h3>
              <h3>Half Legs  <span className="price-r">$45+ </span></h3>
              <h3>Full Legs  <span className="price-r">$75+ </span></h3>
              <h3>Back  <span className="price-r">$50+ </span></h3>
              <h3>Chest  <span className="price-r">$45+ </span></h3>
              <h3>Bikini  <span className="price-r">$45+ </span></h3>
              <h3>Brazilian  <span className="price-r">$60+ </span></h3>
            </div>
          </div>
          {/* .service-panel end */}
        </div>
        {/* .col-lg-12 end */}
      </div>
      {/* .row end */}
    </div>
    {/* .container end */}
  </section>
  {/* #service1 end */}
   </>
  );
};

export default Services;
