import React from 'react';
import { Link } from 'react-router-dom';
import { DatePicker  } from 'antd';
import moment from 'moment'
import { Statistic,Result,Spin,Row, Col } from 'antd';
import { useState } from 'react';
import { PhoneOutlined } from '@ant-design/icons';

const HostAParty = () => {
  const dateFormat = 'MM/DD/YYYY';
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    phone: '',
   
    date: '',
    message: '',
    time:'',
    party_size:'',
    buttonText: 'Submit',
    formData: new FormData(),
  });
  const {
    name,
    phone,
   
    message,
    date,
    time,
    party_size,
    buttonText,
    formData,
  } = values;
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };
  const hostAParty = (information) => {
    return fetch(
      `https://www.flexyform.com/f/8d96a9ce6e01c14863d669cec34fe9355bf5bb3f`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: information,
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValues({ ...values, buttonText: 'Sending ... ' });
    try {
      hostAParty(formData).then((data) => {
        setValues({
          ...values,
          name: '',
          phone: '',
          
          message: '',  
          date:'',
          party_size:'',
          time:'',
          buttonText:'Submit'         
        });
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      setValues({
        ...values,
        buttonText: 'Submit',
      });
    }
  };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Host A Party</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Host A Party</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* Service #1
  ===========================================*/}
    <section className="service service-1">
      <div className="container">
        <div className="row heading heading-2 mb-60">

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="service-panel">
              <div className="service-content service-block">
                <div className="contentparty">
                  <div className="txt_hostparty">Our Salon is the perfect place to host a bridal shower, birthday celebration, bachelorette/bachelor party, corporate event or a just a get-together for friends, family, and co-workers. We have a beautiful and accommodating space, affordable prices to fit everyone's budget, and a large team of technicians to serve your needs so you and your guests can relax and enjoy the party. Please inquire for details by giving us a call or fill out the form.</div>
                  <div className="img_hostparty">
                    <img src={require('./../assets/images/01.jpg').default} alt="nail-party" />
                    <img src={require('./../assets/images/02.jpg').default} alt="nail-party" />
                  </div> 
                  {!success && (
                    <>
                    {loading ? (
                      <Spin tip="Sending ...">
                      <div className="contact_party">
                      {/*Contact form Begin*/}
                      <form id="form" onSubmit={handleSubmit}>
                        <div className="formrow">
                          <div className="formrowtop">Your Name*:</div>
                          <div className="formrowtext"><input className="txtbox" type="text"  id="name" onChange={handleChange('name')}
                          value={name} placeholder="Your Name" required /></div>
                        </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Phone Number*:</div>
                          <div className="formrowtext"><input className="txtbox" type="number" onChange={handleChange('phone')}
                          value={phone} id="phone" placeholder="Your Phone" required /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Party Size*:</div>
                          <div className="formrowtext">
                            <select className="txtbox" name="party_size" 
                            onChange={handleChange('party_size')}
                            value={party_size} id="party_size" required>
                              <option value="Party Size" disabled>**Choose Party Size**</option>
                              
                              <option value="1 Person">1 Person</option>
                              <option value="2 People">2 People</option>
                              <option value="3 People">3 People</option>
                              <option value="4 People">4 People</option>
                              <option value="5 People">5 People</option>
                              <option value="6 People">6 People</option>
                              <option value="7 People">7 People</option>
                              <option value="8 People">8 People</option>
                              <option value="9 People">9 People</option>
                              <option value="10 People">10 People</option>
                              <option value="> 10 People">More Than 10 People (Need Confirm By Phone)</option>
                            </select>                   
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Date*:</div>
                          <div className="formrowtext"> <DatePicker required className="txtbox" disabledDate={(current) => {
                            return moment().add(-1, 'days')  >= current;
                            }} format={dateFormat} /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Time*:</div>
                          <div className="formrowtext">
                          <select className="txtbox" name="time" 
                          onChange={handleChange('time')}
                          value={time} id="time">
                            <option value="Party Size" disabled>**Choose Available Time Slot**</option>                      
                            <option value="9:00 AM">9:00 AM</option>
                            <option value="9:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="12:00 AM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="1:00 PM">1:00 PM</option>
                            <option value="1:30 PM">1:30 PM</option>
                            <option value="2:00 PM">2:00 PM</option>
                            <option value="2:30 PM">2:30 PM</option>
                            <option value="3:00 PM">3:00 PM</option>
                            <option value="3:30 PM">3:30 PM</option>
                            <option value="4:00 PM">4:00 PM</option>
                            <option value="4:30 PM">4:30 PM</option>
                            <option value="5:00 PM">5:00 PM</option>
                            <option value="5:30 PM">5:30 PM</option>
                            <option value="6:30 PM">6:30 PM</option>
                        </select>                   
                      </div>                
                          </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Additional Message*:</div>
                          <div className="formrowtext"><textarea className="txtbox textarea" rows={10} placeholder="Message" name="message" 
                          onChange={handleChange('message')}
                          value={message} id="message" /></div>
                        </div>
                        <div className="formrow buttonrow">
                          
                          <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        
                      
                      {/*Contact form End*/}
                    </form>
                  </div>
  
                      </Spin>
                    ):(
                      <div className="contact_party">
                      {/*Contact form Begin*/}
                      <form id="form" onSubmit={handleSubmit}>
                        <div className="formrow">
                          <div className="formrowtop">Your Name*:</div>
                          <div className="formrowtext"><input className="txtbox" type="text"  id="name" onChange={handleChange('name')}
                          value={name} placeholder="Your Name" required /></div>
                        </div>
                        
                        <div className="formrow">
                          <div className="formrowtop">Phone Number*:</div>
                          <div className="formrowtext"><input className="txtbox" type="number" onChange={handleChange('phone')}
                          value={phone} id="phone" placeholder="Your Phone" required /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Party Size*:</div>
                          <div className="formrowtext">
                            <select className="txtbox" name="party_size" 
                            onChange={handleChange('party_size')}
                            value={party_size} id="party_size" required>
                              <option value="Party Size" disabled>**Choose Party Size**</option>                             
                              <option value="1 Person">1 Person</option>
                              <option value="2 People">2 People</option>
                              <option value="3 People">3 People</option>
                              <option value="4 People">4 People</option>
                              <option value="5 People">5 People</option>
                              <option value="6 People">6 People</option>
                              <option value="7 People">7 People</option>
                              <option value="8 People">8 People</option>
                              <option value="9 People">9 People</option>
                              <option value="10 People">10 People</option>
                              <option value="> 10 People">More Than 10 People (Need Confirm By Phone)</option>
                            </select>                   
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Date*:</div>
                          <div className="formrowtext">
                          <input
                          id="date"
                          name="date"
                          type="date"
                          className="txtbox"
                          onChange={handleChange('date')}
                          value={date}
                          
                          required
                        />
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Time*:</div>
                          <div className="formrowtext">
                          <select className="txtbox" name="time" 
                          onChange={handleChange('time')}
                          value={time} id="time" required>
                            <option value="Party Size" disabled>**Choose Available Time Slot**</option>                      
                            <option value="9:00 AM">9:00 AM</option>
                            <option value="9:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="12:00 AM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="1:00 PM">1:00 PM</option>
                            <option value="1:30 PM">1:30 PM</option>
                            <option value="2:00 PM">2:00 PM</option>
                            <option value="2:30 PM">2:30 PM</option>
                            <option value="3:00 PM">3:00 PM</option>
                            <option value="3:30 PM">3:30 PM</option>
                            <option value="4:00 PM">4:00 PM</option>
                            <option value="4:30 PM">4:30 PM</option>
                            <option value="5:00 PM">5:00 PM</option>
                            <option value="5:30 PM">5:30 PM</option>
                            <option value="6:30 PM">6:30 PM</option>
                        </select>                   
                      </div>                
                          </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Additional Message:</div>
                          <div className="formrowtext"><textarea className="txtbox textarea" rows={10} placeholder="Message" name="message" 
                          onChange={handleChange('message')}
                          value={message} id="message" /></div>
                        </div>
                        <div className="formrow buttonrow">
                          
                          <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        
                      
                      {/*Contact form End*/}
                    </form>
                  </div>
  
                    )}
                    </>      
                  )}
                  
            {success && (
              <div className="contact_party">
              <Result
              status="success"
              title="Congratulations! You Submitted Your Appointment Successfully!"
              subTitle="Thank you so much.We will try to get back to you as soon as possible."
/>
              
              </div>
            )}

              </div>
            </div>
            {/* .service-panel end */}
          </div>
          {/* .col-lg-12 end */}
        </div>



        {/* .row end */}
      </div>
      {/* .container end */}
      </div>
    </section>
  </>
  
  );
};

export default HostAParty;
