import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Appointment from './pages/Appointment';
import EGift from './pages/EGift';
import HostAParty from './pages/HostAParty';
import HappyHour from './pages/HappyHour';
import Promotions from './pages/Promotions';
import SignUp from './pages/SignUp';
import Gallery2 from './pages/Gallery2';
import Gallery3 from './pages/Gallery3';

const Routes = () => {
  return (
    <BrowserRouter>
      <>         
          <div id="wrap_notifi">
            <div className="preloader">
              <div className="spinner">
                <div className="double-bounce1" />
                <div className="double-bounce2" />
              </div>
            </div>
            <div id="wrapper" className="wrapper clearfix">
              <Header />
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/services" exact component={Services} />
                  <Route path="/gallery" exact component={Gallery} />
                  <Route path="/gallery-2" exact component={Gallery2} />
                  <Route path="/gallery-3" exact component={Gallery3} />
                  <Route path="/contact" exact component={Contact} />
                  <Route path="/appointment" exact component={Appointment} />
                  <Route path="/e-gift" exact component={EGift} />
                  <Route path="/happy-hour" exact component={HappyHour} />
                  <Route path="/host-a-party" exact component={HostAParty} />
                  <Route path="/promotions" exact component={Promotions} />
                  <Route path="/sign-up" exact component={SignUp} />
                  <Route component={Error} />
                </Switch>
              <Footer />
            </div>{/* #wrapper end */}
        <a href="/appointment" id="subbar"><i className="fa fa-calendar-check-o" /><span>Appointment</span></a>
        
          <div className="groupfbye">
            <a href="https://www.facebook.com/orchidsnail/" target="_blank"><i className="fa fa-facebook" /></a>
            <div className="line" />
            <a href="https://www.yelp.com/biz/orchids-nails-and-spa-tulsa" target="_blank"><i className="fa fa-yelp" /></a>
            <div className="line"> </div>
            <a href="https://www.google.com/search?hl=en&_ga=2.268631857.13750825.1598471717-1507255196.1578935786&q=Orchids%20Nails%20%26%20Spa&ludocid=7923268391581984539&lsig=AB86z5UL2JU1Ib-wrOWLiC7W5u34" target="_blank"><i className="fa fa-google" /></a>         
          </div>
        </div>
        <div id="linktop" ><i className="fa fa-arrow-circle-up" /></div>
      </>
    </BrowserRouter>
  );
};

export default Routes;
