import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Result, Spin } from 'antd';

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    buttonText: 'SEND',
    formData: new FormData(),
  });
  const {
    name,
    phone,
    email,
    message,
    buttonText,
    formData,
  } = values;
  const handleChange = (name) => (event) => {
    formData.set(name, event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };
  const contactUs = (information) => {
    return fetch(
      `https://www.flexyform.com/f/01335517fe68a0453c5d33085aaee4ab2ef6d50f`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: information,
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValues({ ...values, buttonText: 'SENDING ... ' });
    try {
      contactUs(formData).then((data) => {
        setValues({
          ...values,
          name: '',
          phone: '',
          email: '',
          message: '',  
          buttonText:'SEND'         
        });
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      setValues({
        ...values,
        buttonText: 'SEND',
      });
    }
  };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Contact Us</h1>
                </div>
                <ol className="breadcrumb">
                  <li><Link to="/">Home</Link></li>
                  <li className="active">Contact</li>
                </ol>
              </div>
            </div>{/* .page-title end */}
          </div>{/* .col-lg-12 end */}
        </div>{/* .row end */}
      </div>{/* .container end */}
    </section>{/* #page-title end */}
    {/* Contact Info
      ============================================= */}
    <section id="contact1" className="contact contact-1">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="heading heading-2 heading-9">
              <div className="subheading">We Would Love To Hear From You</div>
              <h2 className="mb-0">Contact Us</h2>
            </div>{/* .heading end */}
            <div className="contact-info">
              <div className="contact-bio">Feel free to contact us for any questions !</div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="contact-panel mb-30">
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" />
                    </div>{/* .contact-icon end */}
                    <div className="contact-content">
                      <h6>Address:</h6>
                      <p>4302 S Peoria Ave<br />Tulsa, Oklahoma 74105</p>
                    </div>{/* .contact-content end */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="contact-panel mb-30">
                    <div className="contact-icon">
                      <i className="fa fa-clock-o" />
                    </div>{/* .contact-icon end */}
                    <div className="contact-content">
                      <h6>Business Hours</h6>
                      <p>Mon - Sat: 9AM - 7PM</p>
                      <p>Sun: 12PM - 6PM</p>
                    </div>{/* .contact-content end */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="contact-panel">
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>{/* .contact-icon end */}
                    <div className="contact-content">
                      <h6>Phone:</h6>
                      <p>918-935-2238</p>
                    </div>{/* .contact-content end */}
                  </div>
                </div>
              </div>{/* .row end */}
            </div>{/* .contact-info end */}
          </div>
          {/* .col-lg-4 end */}
          {!success && (<>
            {
              loading ? (<>
              
                  <div className="col-sm-12 col-md-12 col-lg-8">
                  <Spin tip="Sending...">
                  <div className="contact-form bg-gray">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="form-heading">
                          Contact Form
                        </div>
                      </div>
                    </div>
                    <form id="contact-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <label>NAME:</label>
                          <input type="text" className="form-control mb-30" 
                          name="contact-name" id="name" onChange={handleChange('name')}
                          value={name} required />
                        </div>
                        <div className="col-md-6">
                          <label>PHONE:</label>
                          <input type="text" className="form-control mb-30" 
                          name="contact-phone" id="phone" onChange={handleChange('phone')}
                          value={phone} required />
                        </div>
                        <div className="col-md-12">
                          <label>EMAIL:</label>
                          <input type="email" className="form-control mb-30" name="contact-email" 
                          onChange={handleChange('email')}
                          value={email} id="email" required />
                        </div>
                        <div className="col-md-12">
                          <label>YOUR MESSAGE:</label>
                          <textarea className="form-control mb-30" name="contact-message" onChange={handleChange('message')}
                          value={message} id="message" rows={2} required />
                        </div>
                        <div className="col-md-12">
                          <button type="submit" id="submit-message" className="btn btn-block btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {/*Alert Message*/}
                          <div id="contact-result">
                          </div>
                        </div>
                      </div>{/* .row end */}
                    </form>
                  </div>{/* .contact-form end */}
                  </Spin>
                  </div>{/* .col-lg-8 end */}
               
                  </>):
                (<>                
                  <div className="col-sm-12 col-md-12 col-lg-8">
                  
                  <div className="contact-form bg-gray">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="form-heading">
                          Contact Form
                        </div>
                      </div>
                    </div>
                    <form id="contact-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <label>NAME:</label>
                          <input type="text" className="form-control mb-30" 
                          name="contact-name" id="name" onChange={handleChange('name')}
                          value={name} required />
                        </div>
                        <div className="col-md-6">
                          <label>PHONE:</label>
                          <input type="text" className="form-control mb-30" 
                          name="contact-phone" id="phone" onChange={handleChange('phone')}
                          value={phone} required />
                        </div>
                        <div className="col-md-12">
                          <label>EMAIL:</label>
                          <input type="email" className="form-control mb-30" name="contact-email" 
                          onChange={handleChange('email')}
                          value={email} id="email" required />
                        </div>
                        <div className="col-md-12">
                          <label>YOUR MESSAGE:</label>
                          <textarea className="form-control mb-30" name="contact-message" onChange={handleChange('message')}
                          value={message} id="message" rows={2} required />
                        </div>
                        <div className="col-md-12">
                          <button type="submit" id="submit-message" className="btn btn-block btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          
                          <div id="contact-result">
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  
                  </div>
                
                </>)
            
            }
            </>
            ) }
          
      {/* SHOW SUCCESS */}
      {success && ( 
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="contact-form bg-gray">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
              <Result
                status="success"
                title="Successfully Submit Your Message!"
                subTitle="Thank you for your message.We will try to get back to you as soon as possible."
  />
              </div>
            </div>
          
          </div>
    </div>)}
         

    {/* END SHOW SUCCESS */}



        </div>{/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #contact1 end */}
  </>
  
  );
};

export default Contact;
