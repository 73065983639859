import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const Header = ({ history }) => {
  const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return 'active';
    } else {
      return '';
    }
  };
  return (
   <>
   <header id="navbar-spy" className="header header-1">
   <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light">
     <div className="container-fluid container-p0">
       <Link className="navbar-brand" to="/">
         <img src={require('./../assets/images/logo/logo-white.png').default} alt="celia template" />
       </Link>
       <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
         <span className="navbar-toggler-icon" />
       </button>
       <div className="groupmenu_m">
         <ul>
           <li id="call_m"><a href="tel:9189352238"><i className="fa fa-phone" /><span>Call</span></a></li>
           <li id="services_m"><Link to="/"><i className="fa fa-home" /><span>Home</span></Link></li>
           <li id="services_m"><Link to="/services"><i className="fa fa-magic" /><span>Services</span></Link></li>
           <li id="direction_m"><a target="_blank" href="https://www.google.com/maps/place/Orchids+Nails+%26+Spa/@36.1005092,-95.9783481,17z/data=!4m5!3m4!1s0x87b693f1ba5d15d3:0x6df51a9f7453271b!8m2!3d36.1005092!4d-95.9761594" ><i className="fa fa-map-marker" /><span>Direction</span></a></li> 
           <li id="subbar_m"><Link to="/gallery"><i className="fa fa-picture-o" /><span>Gallery</span></Link></li>
           <li id="subbar_m"><Link to="/appointment"><i class="fa fa-calendar-check-o" /><span>Booking</span></Link></li>
         </ul>
       </div>
       {/* Collect the nav links, forms, and other content for toggling */}
       <div className="collapse navbar-collapse menu_mobi" id="navbarContent">
         <ul className="navbar-nav ml-auto">
           {/* Home Menu */}
           <li className={isActive(history, '/')}>
             <a href="/" className="menu-item">home</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/services')}>
             <a href="/services" className="menu-item">services</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/gallery')}>
             <a href="/gallery" className="menu-item">gallery</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/e-gift')}>
             <a href="/e-gift" className="menu-item">gift</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/host-a-party')}>
             <a href="/host-a-party" className="menu-item">party</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/happy-hour')}>
             <a href="/happy-hour" className="menu-item">happy hour</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/promotions')}>
             <a href="/promotions" className="menu-item">promotions</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>
           <li className={isActive(history, '/sign-up')}>
           <a href="/sign-up" className="menu-item">sign up</a>
           <ul className="dropdown-menu">
             <li>&nbsp;</li>
           </ul>
         </li>
           <li className={isActive(history, '/contact')}>
             <a href="/contact" className="menu-item">contact</a>
             <ul className="dropdown-menu">
               <li>&nbsp;</li>
             </ul>
           </li>

         </ul>
         
         <ul>
         <li><a href="/appointment" className="rButton"><i class="fa fa-calendar-check-o"></i>&nbsp;Appointment</a></li>
         
         </ul>
       </div>
       {/* /.navbar-collapse */}
     </div>
     {/* /.container */}
   </nav>
 </header>
   </>
  );
};

export default withRouter(Header);
