import React from 'react';
import { Link } from 'react-router-dom';
import { Result,Spin } from 'antd';
import { useState } from 'react';
import Typewriter from 'typewriter-effect';

const Appointment = () => {
  const dateFormat = 'MM/DD/YYYY';
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    date: '',
    service:'',
    message: '',
    time:'',
    number_of_people:'',
    buttonText: 'Submit',
    formData: new FormData(),
  });
  const {
    name,
    phone,
    number_of_people,
    service,
    message,
    date,
    time,
    buttonText,
    formData,
  } = values;
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };
  const appointment = (information) => {
    return fetch(
      `https://www.flexyform.com/f/fde704874ea74a133ef71cf6d971c4e2a5a15577`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: information,
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValues({ ...values, buttonText: 'Sending ... ' });
    try {
      appointment(formData).then((data) => {
        setValues({
          ...values,
          name: '',
          phone: '',
          service:'',
          message: '',  
          date:'',
          number_of_people:'',
          time:'',
          buttonText:'Submit'         
        });
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      setValues({
        ...values,
        buttonText: 'Submit',
      });
    }
  };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Appointment</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Appointment</li>
                </ol>
              </div>
            </div>
           
          </div>
          
        </div>
       
      </div>
     
    </section>
  
    <section className="service service-1">
      <div className="container">
        <div className="row heading heading-2 mb-60">

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="service-panel">
              <div className="service-content service-block">
                <div className="contentparty">
                  <div className="subheading" style={{fontSize:'28px','paddingBottom':'60px'}}><Typewriter
                  options={{
                    strings: 'Please Schedule Your Appointment Today!',
                    autoStart: true,
                    loop: true,
                  }} /></div>
                  
                  {!success && (
                    <>
                    {loading ? (
                      <Spin tip="Sending ...">
                      <div className="contact_party">
                      {/*Contact form Begin*/}
                      <form id="form" onSubmit={handleSubmit}>
                        <div className="formrow">
                          <div className="formrowtop">Your Name*:</div>
                          <div className="formrowtext"><input className="txtbox" type="text"  id="name" onChange={handleChange('name')}
                          value={name} placeholder="Your Name" required /></div>
                        </div>
                        
                        <div className="formrow">
                          <div className="formrowtop">Phone Number*:</div>
                          <div className="formrowtext"><input className="txtbox" type="number" onChange={handleChange('phone')}
                          value={phone} id="phone" placeholder="Your Phone" required /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Number of People*:</div>
                          <div className="formrowtext">
                            <select className="txtbox" name="number_of_people" 
                            onChange={handleChange('number_of_people')}
                            value={number_of_people} id="number_of_people" required>
                              <option value="Choose Number" disabled>**Choose Number**</option>
                              
                              <option value="1 Person">1 Person</option>
                              <option value="2 People">2 People</option>
                              <option value="3 People">3 People</option>
                              <option value="4 People">4 People</option>
                              <option value="5 People">5 People</option>                           
                              <option value="Group">Group (> 5 People)</option>
                            </select>                   
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Date*:</div>
                          <div className="formrowtext">  <input
                          id="date"
                          name="date"
                          type="date"
                          className="txtbox"
                          onChange={handleChange('date')}
                          value={date}
                         
                          required
                        /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Time*:</div>
                          <div className="formrowtext">
                          <select className="txtbox" name="time" 
                          onChange={handleChange('time')}
                          value={time} id="time">
                            <option value="Party Size" disabled>**Choose Available Time Slot**</option>                      
                            <option value="9:00 AM">9:00 AM</option>
                            <option value="9:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="12:00 AM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="1:00 PM">1:00 PM</option>
                            <option value="1:30 PM">1:30 PM</option>
                            <option value="2:00 PM">2:00 PM</option>
                            <option value="2:30 PM">2:30 PM</option>
                            <option value="3:00 PM">3:00 PM</option>
                            <option value="3:30 PM">3:30 PM</option>
                            <option value="4:00 PM">4:00 PM</option>
                            <option value="4:30 PM">4:30 PM</option>
                            <option value="5:00 PM">5:00 PM</option>
                            <option value="5:30 PM">5:30 PM</option>
                            <option value="6:30 PM">6:30 PM</option>
                        </select>                   
                      </div>                
                          </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Additional Message*:</div>
                          <div className="formrowtext"><textarea className="txtbox textarea" rows={10} placeholder="Message" name="message" 
                          onChange={handleChange('message')}
                          value={message} id="message" /></div>
                        </div>
                        <div className="formrow buttonrow">
                          
                          <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        
                      
                      {/*Contact form End*/}
                    </form>
                  </div>
  
                      </Spin>
                    ):(
                      <div className="contact_party">
                      {/*Contact form Begin*/}
                      <form id="form" onSubmit={handleSubmit}>
                      <div className="formrow">
                          <div className="formrowtop">Choose Service*:</div>
                          <div className="formrowtext">
                            <select className="txtbox" name="service" 
                            onChange={handleChange('service')}
                            value={service} id="service" required>
                            <option value="MANICURE" disabled>
                            **MANICURE**
                          </option>
                          <option value="Classic Spa Manicure ($20)">
                            Classic Spa Manicure ($20)
                          </option>
                          <option value="Summer Citrus Manicure ($35)">
                            Summer Citrus Manicure ($35)
                          </option>
                          <option value="Autumn Chamomile Manicure ($45)">
                          Autumn Chamomile Manicure ($45)
                          </option>
                          <option value="Spring Rose Milky Manicure ($55)">
                          Spring Rose Milky Manicure ($55)
                          </option>
                          <option value="Winter Botanical Herbal Manicure ($65)">
                          Winter Botanical Herbal Manicure ($65)
                          </option>
      
                          <option value="Pedicure" disabled>
                            **PEDICURE**
                          </option>
                          <option value="Classic Spa Pedicure ($39)">
                          Classic Spa Pedicure ($39)
                          </option>
                          <option value="Summer Citrus Pedicure ($49)">
                          Summer Citrus Pedicure ($49)
                          </option>
                          <option value="Autumn Chamomile Pedicure ($69)">
                          Autumn Chamomile Pedicure ($69)
                          </option>
                          <option value="Spring Rose Milky Pedicuree ($79)">
                          Spring Rose Milky Pedicuree ($79)
                          </option>
                          <option value="Winter Botanical Herbal Pedicure ($89)">
                          Winter Botanical Herbal Pedicure ($89)
                          </option>
                          
                          <option value="NAIL ENHANCEMENT" disabled>
                            **NAIL ENHANCEMENT**
                          </option>                         
                          <option value="Healthy Dip ($50)">Healthy Dip ($50)</option>
                          <option value="French Dip ($55)">French Dip ($55)</option>
                          <option value="Healthy Shellac ($40)">Healthy Shellac ($40)</option>
                          <option value="Healthy French ($45)">Healthy French ($45)</option>
                          <option value="Add-on" disabled>
                            **ADD ON**
                          </option>     
                          <option value="Shellac Color ($25)">
                          Shellac Color ($25)
                          </option>
                          <option value="Shellac French ($30)">Shellac French ($30)</option>
                          <option value="Paraffin ($15)">
                          Paraffin ($15)
                          </option>
                          <option value="French ($5)">
                          French ($5)
                          </option>
                          <option value="Design ($10)">
                          Design ($10)
                          </option>
                          <option value="EYELASH EXTENSION" disabled>
                            **EYELASH EXTENSION**
                          </option>
                          <option value="Eyelash Full Set ($150)">
                            Eyelash Full Set ($150)
                          </option>
                          <option value="Eyelash Refill ($45+)">
                            Eyelash Refill ($45+)
                          </option>
                          <option value="Eyelash Express ($45)">
                            Eyelash Express ($45)
                          </option>
                          <option value="Eyebrow Tinting ($25)">
                            Eyebrow Tinting ($25)
                          </option>
      
                          <option value="FACIAL" disabled>
                            **FACIAL**
                          </option>
                          <option value="MINI FACIAL (30 MINUTES) ($50)">
                            MINI FACIAL (30 MINUTES) ($50)
                          </option>
                          <option value="DAY SPA FACIAL (60 MINUTES) ($75)">
                           DAY SPA FACIAL (60 MINUTES) ($75)
                          </option>
                          <option value="Signature Orchids Facial ( 80 Minutes ) ($95)">
                          Signature Orchids Facial ( 80 Minutes ) ($95)
                          </option>
      
                          <option value="WAXING" disabled>
                            **WAXING**
                          </option>
                          <option value="Eyebrows ($15)">Eyebrows ($15)</option>
                          <option value="Lips ($7)">Lips ($7)</option>
                          <option value="Chin ($10)">Chin ($10)</option>
                          <option value="Sideburns ($25)">Sideburns ($25)</option>
                          <option value="Full Face ($50)">Full Face ($50)</option>
                          <option value="Stomach ($30+)">Stomach ($30+)</option>
                          <option value="Ears ($20+)">Ears ($20+)</option>
                          <option value="Underarms ($30)">Underarms ($30)</option>
                          <option value="Half Arms ($35+)">Half Arms ($35+)</option>
                          <option value="Full Arms ($60+)">Full Arms ($60+)</option>
                          <option value="Half Legs ($45+)">Half Legs ($45+)</option>
                          <option value="Full Legs ($75+)">Full Legs ($75+)</option>
                          <option value="Back ($50+)">Back ($50+)</option>
                          <option value="Chest ($45+)">Chest ($45+)</option>
                          <option value="Bikini ($45+)">Bikini ($45+)</option>
                          <option value="Brazilian ($60+)">Brazilian ($60+)</option>
      
                         
                            </select>                   
                          </div>
                      </div>
                        <div className="formrow">
                          <div className="formrowtop">Your Name*:</div>
                          <div className="formrowtext"><input className="txtbox" type="text"  id="name" onChange={handleChange('name')}
                          value={name} placeholder="Your Name" required /></div>
                        </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Phone Number*:</div>
                          <div className="formrowtext"><input className="txtbox" type="number" onChange={handleChange('phone')}
                          value={phone} id="phone" placeholder="Your Phone" required /></div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Choose Number of People*:</div>
                          <div className="formrowtext">
                            <select className="txtbox" name="number_of_people" 
                            onChange={handleChange('number_of_people')}
                            value={number_of_people} id="number_of_people" required>
                              <option value="Choose Number" disabled>**Choose Number**</option>                             
                              <option value="1 Person">1 Person</option>
                              <option value="2 People">2 People</option>
                              <option value="3 People">3 People</option>
                              <option value="4 People">4 People</option>
                              <option value="5 People">5 People</option>
                              
                              <option value="Group">Group (> 5 Peple)</option>
                            </select>                   
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Date*:</div>
                          <div className="formrowtext">
                          <input
                          id="date"
                          name="date"
                          type="date"
                          className="txtbox"
                          onChange={handleChange('date')}
                          value={date}                        
                          required
                        />
                          </div>
                        </div>
                        <div className="formrow">
                          <div className="formrowtop">Time*:</div>
                          <div className="formrowtext">
                          <select className="txtbox" name="time" 
                          onChange={handleChange('time')}
                          value={time} id="time" required>
                            <option value="Party Size" disabled>**Choose Available Time Slot**</option>                      
                            <option value="9:00 AM">9:00 AM</option>
                            <option value="9:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="12:00 AM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="1:00 PM">1:00 PM</option>
                            <option value="1:30 PM">1:30 PM</option>
                            <option value="2:00 PM">2:00 PM</option>
                            <option value="2:30 PM">2:30 PM</option>
                            <option value="3:00 PM">3:00 PM</option>
                            <option value="3:30 PM">3:30 PM</option>
                            <option value="4:00 PM">4:00 PM</option>
                            <option value="4:30 PM">4:30 PM</option>
                            <option value="5:00 PM">5:00 PM</option>
                            <option value="5:30 PM">5:30 PM</option>
                            <option value="6:30 PM">6:30 PM</option>
                        </select>                   
                      </div>                
                          </div>
                       
                        <div className="formrow">
                          <div className="formrowtop">Additional Message:</div>
                          <div className="formrowtext"><textarea className="txtbox textarea" rows={10} placeholder="Message" name="message" 
                          onChange={handleChange('message')}
                          value={message} id="message" /></div>
                        </div>
                        <div className="formrow buttonrow">
                          
                          <button type="submit" className="btn btn-secondary btn-hover">{buttonText}</button>
                        </div>
                        
                      
                      {/*Contact form End*/}
                    </form>
                  </div>
  
                    )}
                    </>      
                  )}
                  
            {success && (
              <div className="contact_party">
              <Result
              status="success"
              title="Congratulations! You Submitted Your Appointment Successfully!"
              subTitle="Thank you so much.We will try to get back to you as soon as possible."
/>
              
              </div>
            )}

              </div>
            </div>
            {/* .service-panel end */}
          </div>
          {/* .col-lg-12 end */}
        </div>



        {/* .row end */}
      </div>
      {/* .container end */}
      </div>
    </section>
  </>
  
  );
};

export default Appointment;
