import React from 'react';
import { Link } from 'react-router-dom';

const EGift = () => {
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Our E-Gift</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">E-Gift</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* CONTACT FORM */}
    <div className="page-section pt-110-b-80-cont">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{textAlign: 'center', minHeight: 300, paddingTop: 30, color: '#333'}}>Coming Soon...</div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  );
};

export default EGift;
