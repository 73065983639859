import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';

const Gallery2 = ({history}) => {
  const next = () => {
    history.push("/gallery-3")
   };
   const previous = () => {
    history.push("/gallery")
   };
  return (
    <>
    <section id="page-title" className="page-title">
      <div className="container-fluid bg-overlay bg-section">
       
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-1">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Our Gallery</h1>
                </div>
                <ol className="breadcrumb">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li className="active">Gallery</li>
                </ol>
              </div>
            </div>
            {/* .page-title end */}
          </div>
          {/* .col-lg-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
    {/* #page-title end */}
    {/* Gallery #1
    ===========================================*/}
    <section className="portfolio portfolio-vintage portfolio-3col portfolio-animation pb-40">
      <div className="container">
        <div className="row heading heading-2 mb-70">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Our Works of Art</div>
          </div>{/* .col-lg-12 end */}
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Created with Pride.</h2>
          </div>
        </div>
        {/* .row end */}
      </div>{/* .container end */}
      <div className="container">
        <div id="portfolio-all" className="row">
       
        

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227118/orchids-nails-salon/20_xorwaf.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227118/orchids-nails-salon/20_xorwaf.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227164/orchids-nails-salon/13_prpw89.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227164/orchids-nails-salon/13_prpw89.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/19_zqsikz.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227117/orchids-nails-salon/19_zqsikz.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227119/orchids-nails-salon/30_siu7to.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227119/orchids-nails-salon/30_siu7to.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/24_omvugo.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/24_omvugo.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/25_rrs6zz.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/25_rrs6zz.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/43_mknxiw.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/43_mknxiw.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/27_hvuotq.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227121/orchids-nails-salon/27_hvuotq.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227129/orchids-nails-salon/29_gen9ya.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227129/orchids-nails-salon/29_gen9ya.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227115/orchids-nails-salon/23_k1gwda.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227115/orchids-nails-salon/23_k1gwda.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227124/orchids-nails-salon/31_gqcupm.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227124/orchids-nails-salon/31_gqcupm.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227118/orchids-nails-salon/26_lu4vo4.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227118/orchids-nails-salon/26_lu4vo4.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/41_yxyl8a.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/41_yxyl8a.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/44_scyagd.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/44_scyagd.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/45_vax784.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/45_vax784.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533356/orchids-nails-salon/46_p4wh1g.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533356/orchids-nails-salon/46_p4wh1g.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533356/orchids-nails-salon/47_exxjw0.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533356/orchids-nails-salon/47_exxjw0.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/42_teumhs.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/42_teumhs.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227124/orchids-nails-salon/32_dptzcy.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606227124/orchids-nails-salon/32_dptzcy.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div className="col-sm-12 col-md-6 col-lg-4 portfolio-item filter-Packaging filter-Interior">
<div className="portfolio-item-box">
  <div className="portfolio-img">
    <img src='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/40_ongvof.jpg' alt="Gallery" />
    <div className="portfolio-hover">
      <div className="portfolio-hover-content">
        <div className="portfolio-meta">
          <div className="portfolio-title">
            <h4><a href="#"><i className="fa fa-instagram" /></a></h4>
          </div>
        </div>
        <div className="portfolio-action">
          <div className="portfolio-zoom-border">
            <a className="img-popup zoom" href='https://res.cloudinary.com/dtopnho1y/image/upload/v1606533357/orchids-nails-salon/40_ongvof.jpg' alt="Gallery">
            <i className="fa fa-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>





        </div>{/* .row end */}
        <ul className="ant-pagination" unselectable="unselectable" style={{textAlign:'center'}}>
    <li title="Previous Page" className="ant-pagination-prev" aria-disabled="true">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" onClick={previous}>
            <span role="img" aria-label="left" className="anticon anticon-left">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                </svg>
            </span>
        </button>
    </li>
    <li title="{1}" className="ant-pagination-item ant-pagination-item-1" tabindex="{0}"><a href="/gallery" rel="nofollow">1</a></li>
    <li title="{2}" className="ant-pagination-item ant-pagination-item-2 ant-pagination-item-active" tabindex="{0}"><a rel="nofollow">2</a></li>
    <li title="{3}" className="ant-pagination-item ant-pagination-item-3" tabindex="{0}"><a href="/gallery-3" rel="nofollow">3</a></li>
   
    <li title="Next Page" className="ant-pagination-next" aria-disabled="false" tabindex="{0}">
        <button className="ant-pagination-item-link" type="button" tabindex="{-1}" onClick={next}>
            <span role="img" aria-label="right" className="anticon anticon-right">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
                </svg>
            </span>
        </button>
    </li>
</ul>

      </div>
      {/* .container end */}



    </section>{/* #portfolio-masonry end */}
  </>
  
  );
};

export default Gallery2;
