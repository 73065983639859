import React from 'react';
import { Result, Button } from 'antd';

const Error = ({history}) => {
  const backHome = () => {
    history.push("/");
  }
  return (
   <><Result
   status="404"
   title="404"
   subTitle="Sorry, the page you visited does not exist."
   extra={<Button onClick={backHome} type="primary">Back Home</Button>}
 />
 </>
  );
};

export default Error;
